import error from '../../assets/images/icons/error.svg';

import { IProps } from '../../interfaces/components/flashError.interface';

const FlashError = ({ text, metadataText }: IProps) => {
  return (
    <div className="flash-error">
      <img src={error} alt="alert-icon" className="block m-r-sm" />
      <div>
        <span className="flash-error-text">{text}</span>
        {metadataText && <div className="flash-error-meta">{metadataText}</div>}
      </div>
    </div>
  );
};

export default FlashError;
