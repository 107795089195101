// disabling until the component map/children component types can be fleshed out more
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IComponent, IComponentProps, IRendererProps } from '../../interfaces/components/renderer.interface';

const Renderer = (props: IRendererProps): React.ReactElement => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    componentMap, componentTree, setComponentTree,
  } = props;
  return (
    <>
      {componentTree.map((component: IComponent) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const Component: React.FC<IComponentProps> = componentMap[component.componentType];
        return Component ? (
          <Component {...component} key={component.reactKey} setComponentTree={setComponentTree}>
            {component.childrenComponents
              && (
                <Renderer
                  componentTree={component.childrenComponents}
                  componentMap={componentMap}
                  setComponentTree={setComponentTree}
                />
              )}
          </Component>
        ) : null;
      })}
    </>
  );
};

export default Renderer;
