import { useState } from 'react';
import parse from 'html-react-parser';
import { formatIconPath } from '../../contexts/Theme';
import { IProps } from '../../interfaces/components/privacyPolicyModal.interface';

const PrivacyPolicyModal = ({
  text, privacyPolicy, tableOfContents, title, children, disabled,
}: IProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);

  const splitOnLinks = text.split('|+|');

  return (
    <>
      {/* Privacy Policy Text,Button, and Modal for the Preamble Page */ }
      {modalOpen && (
        <div className="privacy-policy-modal">
          <div className="modal-nav">
            <div className="nav-item">
              <button type="button" onClick={closeModal} className="button-unstyled back-button">
                <img src={formatIconPath('../../assets/images/icons/back.svg')} alt="close icon" className="back-button" />
              </button>
            </div>
            <img src={formatIconPath('/assets/images/icons/smartcar-wheel.svg')} width="40" height="40" alt="smartcar wheel icon" />
            <div className="nav-item" />
          </div>
          <div className="modal-content">
            <h1 className="m-b-xs">{title}</h1>
            <div className="modal-content-table-of-contents">
              <ul>
                {tableOfContents.map((item) => (
                  <li key={item.id}>
                    <a href={`#${item.id}`}>{item.title}</a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="privacy-policy">
              {parse(privacyPolicy)}
            </div>
            {/* continue button */}
            {children}
          </div>
        </div>
      )}
      <div className="privacy-policy-modal-text">
        {splitOnLinks.map((section) => {
          if (!section) return null;
          if (section.includes('@@')) {
            const [linkText, linkUrl] = section.split('@@');
            if (linkUrl && linkText) {
              return (
                <button
                  onClick={() => setModalOpen(true)}
                  key={linkText}
                  id="privacy-policy-modal-button"
                  type="button"
                  className="button button-text-link inline button-auto-width"
                  disabled={disabled}
                >
                  {linkText}
                </button>
              );
            }
          }
          // non-link sections of the text
          return <span key={section}>{section}</span>;
        })}

      </div>
    </>
  );
};

export default PrivacyPolicyModal;
