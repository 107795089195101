/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, {
  useContext, useEffect, useRef,
} from 'react';

import chevron from '../../assets/images/icons/chevron.svg';

import errorHandler from '../../services/sentry';

import { IOptions, IProps } from '../../interfaces/components/select.interface';
import { FormContext } from '../Form/Form';
import { IFormContext } from '../../interfaces/components/form.interface';
import { SelectionsContext } from '../../contexts/Selections';
import { formatIconPath } from '../../contexts/Theme';
import { ISelectionsContext } from '../../interfaces/contexts/selections.interface';

const getDisplayName = (options: IOptions[], currentlySelected: { value: string }) => {
  const selectedOption = options.find((option) => option.code === currentlySelected.value);

  if (selectedOption) {
    return selectedOption.displayName;
  }
  return options[0].displayName;
};

const Select = ({
  icon, id, name, options,
}: IProps) => {
  const isFirstRender = useRef(true);
  const context = useContext<IFormContext | undefined>(FormContext);
  const selectionsContext = useContext<ISelectionsContext | undefined>(SelectionsContext);
  if (!context) {
    return null;
  }
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    context.setFormState({
      ...context.formState,
      [name]: {
        ...context.formState[name],
        value: e.target.value,
      },
    });
    if (selectionsContext && name === 'language') {
      selectionsContext.setLanguage({ value: e.target.value });
    }
    if (selectionsContext && name === 'country') {
      selectionsContext.setCountry({ value: e.target.value });
    }
  };

  const getValue = (fieldName) => {
    if (fieldName === 'language') {
      return selectionsContext?.language;
    } if (fieldName === 'country') {
      return selectionsContext?.country;
    }
    return context.formState[name];
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // indicate first render has occurred
      isFirstRender.current = false;
    } else if (context.submitFormData) {
      // submit to sentry when that's set up
      context.submitFormData()
        .catch((e: Error | string) => errorHandler(e));
    }
  }, [context.formState[name]]);

  return (
    <div key={`select-${id}`} className="custom-select">
      <select
        id={id}
        name={name}
        value={getValue(name).value}
        className="custom-select-overlay"
        onChange={handleChange}
      >
        {options.map((option) => {
          return (
            <option
              key={option.code}
              value={option.code}
              className={option.className}
            >
              {option.displayName}
            </option>
          );
        })}
      </select>
      <label className="custom-selector" htmlFor={id} id={`${id}-selector-label`}>
        <div className="custom-selector-main">
          {icon && (
            <span className="custom-selector-icon">
              <img
                src={formatIconPath(icon.url)}
                alt={icon.alt}
                className="block"
              />
            </span>
          )}
          <span className="custom-selector-text">{getDisplayName(options, getValue(name))}</span>
          <img src={chevron} alt="dropdown arrow" className="focus-indicator" />
        </div>
      </label>
    </div>
  );
};

Select.defaultProps = {
  icon: null,
};

export default Select;
