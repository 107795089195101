/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect } from 'react';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import Page from '../Page';
import {
  Button,
  FileUpload,
  Form,
  Header,
  Image,
  InlineLinks,
  Footer,
} from '../../components';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import { IPageProps } from '../../interfaces/pages/page.interface';

const componentMap = {
  button: Button,
  form: Form,
  fileUpload: FileUpload,
  header: Header,
  image: Image,
  inlineLinks: InlineLinks,
  footer: Footer,
};

const VinImport = (props: IPageProps) => {
  const {
    componentTree, pageMetadata, setComponentTree,
  } = props;
  const { resetError } = useFlashErrorText();
  const { setAppLoading } = useAppLoading();

  const fetchData = useCallback(async () => {
    // first, clear any flash errors
    resetError();
    // set loading indicator
    setAppLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await api.getComponents('/oauth/vin-import');

    // reset loading indicator
    setAppLoading(false);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setComponentTree(data);
  }, []);

  useEffect(() => {
    // need to pass the location object here to ensure getComponents
    // uses the updated location pathname
    fetchData()
      .catch((e: AxiosError) => {
        // reset loading indicator
        setAppLoading(false);
        // display error info if available
        if (e.response?.data) {
          setComponentTree(e.response.data);
        } else {
          errorHandler(e);
        }
      });
  }, [fetchData]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default VinImport;
