import clsx from 'clsx';

import { IProps } from '../../interfaces/components/connectedImage.interface';
import { formatIconPath } from '../../contexts/Theme';

const ConnectedImage = ({ imageLeft, showSmartcarLogo = true }: IProps) => {
  return (
    <div className="connected-image-container">
      <div className="connected-image-background">
        <img
          src={imageLeft.url}
          alt={imageLeft.alt}
          className={clsx('connected-image connected-image-left')}
        />
      </div>
      {showSmartcarLogo && (
        <img
          src={formatIconPath('/assets/images/icons/smartcar-wheel.svg')}
          alt="smartcar logo"
          className={clsx('mini-logo')}
        />
      )}
      <span className="dash" />
      <span className="dash" />
      <div className="connected-image-background connected-image-background-themed">
        <img
          src={formatIconPath('/assets/images/icons/car-transparent.svg')}
          alt="connected vehicle"
          className={clsx('connected-image connected-image-right')}
        />
      </div>
    </div>
  );
};

export default ConnectedImage;
