import { useContext, useEffect, useState } from 'react';

import { IProps } from '../../interfaces/components/toast.interface';
import { FormContext } from '../Form/Form';
import { IFormContext } from '../../interfaces/components/form.interface';

const Toast = ({
  defaultShow, text, type,
}: IProps) => {
  const [showToast, setShowToast] = useState(defaultShow);
  const context = useContext<IFormContext | undefined>(FormContext);

  useEffect(() => {
    setShowToast(defaultShow || (context?.formValidated && type === 'showOnSubmit'));
  }, [context?.formValidated]);

  return (
    <div
      id="toast-message"
      className={`toast ${showToast ? 'show-toast' : ''}`}
    >
      <div className="toast-text">{text}</div>
    </div>
  );
};

Toast.defaultProps = {
  showToast: true,
  showClose: false,
  text: 'message',
};

export default Toast;
