import { useRef } from 'react';
import clsx from 'clsx';
import { IProps } from '../../interfaces/components/listSection.interface';
import Chevron from '../../assets/images/icons/chevron.svg?component';

const ListSectionVinImport = ({ title, className, children }: IProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const hideClass = 'hide-list';
  const handleClick = () => {
    const currentList = listRef.current;
    const isHidden = currentList?.classList.contains(hideClass);
    if (document.getElementsByClassName(hideClass).length === document.getElementsByClassName('vin-list').length - 1 && isHidden) {
      const openList = Array.from(document.getElementsByClassName('list-section-vin-import')).find((el) => (!el.classList.contains(hideClass)));
      openList?.classList.add(hideClass);
    }
    if (isHidden) {
      currentList?.classList.remove(hideClass);
    } else {
      currentList?.classList.add(hideClass);
    }
  };

  return (
    <div className={clsx(className, hideClass)} ref={listRef} data-testid="vin-section">
      <button
        type="button"
        className="button flex align-center"
        onClick={handleClick}
      >
        <Chevron className={clsx('m-r-xs', 'vin-chevron')} stroke="#595959" strokeWidth={1} />
        <h2>{title}</h2>
      </button>
      <div className="vin-list">{children}</div>
    </div>
  );
};

export default ListSectionVinImport;
