import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import errorHandler from '../../services/sentry';

import { IProps } from '../../interfaces/components/nativeAuth.interface';

declare const window: Window & typeof globalThis & {
  SmartcarSDK?: {
    sendMessage: (string) => void;
  }
};

const isCustomEvent = (event: Event): event is CustomEvent => {
  return 'detail' in event;
};

const NativeAuth = ({
  nativeAuthURLs, requestId,
}: IProps) => {
  const navigate = useNavigate();

  const handleSDKResponse = (event) => {
    if (isCustomEvent(event)) {
      if (event.detail) {
        try {
          // oauth capture success, event emitted from SDK has a result obj
          if (event.detail.result) {
            // read the code from the `returnUri`
            const url = new URL(event.detail.result?.returnUri);
            const code = url.searchParams.get('code');

            // go to /redirect page to send the code to the backend
            if (code) {
              navigate(`/redirect?code=${code}`);
            }
            // issue with oauth capture, event emitted from the SDK has an error object
          } else if (event.detail.error) {
            // this
            if (event.detail.error.code === -3200) {
              navigate('/redirect?error=login_cancelled');
            } else {
              throw new Error(event.detail.error.message);
            }
          }
        } catch (err) {
          // report error to sentry
          errorHandler(err as Error);
          // for now, set uncategorized errors to `login_cancelled`
          navigate('/redirect?error=login_cancelled');
        }
      }
    }
  };

  useEffect(() => {
    // if Connect is being launched using one of our mobile SDKs, the mobile SDK will be launched
    // in a webview. The mobile SDK will add the `SmartcarSDK` object to the window object of the
    // webview Connect is launched in
    if (window.SmartcarSDK) {
      const message = {
        jsonrpc: '2.0',
        method: 'oauth',
        params: nativeAuthURLs,
        id: requestId,
      };

      window.SmartcarSDK.sendMessage(JSON.stringify(message));
    }

    // when the VO finishes logging in to the OEM, the SDK will prevent redirecting back to the OEM
    // app, and will emit a `SmartcarSDKResponse` event, which Connect will listen to know the login
    // process finished. The SmartcarSDKResponse event object will contain the redirect URI from the
    // OEM that has the code that we will use to exchange for an OEM access token.
    window.addEventListener('SmartcarSDKResponse', handleSDKResponse);
    return () => {
      window.removeEventListener('SmartcarSDKResponse', handleSDKResponse);
    };
  }, []);

  return (
    <div className="spinner-container">
      <div className="spinner" />
    </div>
  );
};

export default NativeAuth;
