import clsx from 'clsx';

import { IProps } from '../../interfaces/components/listItem.interface';

const ListItem = ({ text, children, className }: IProps) => {
  return (
    <li className={clsx(className)}>
      {text}
      {children}
    </li>
  );
};

ListItem.defaultProps = {
  className: '',
};

export default ListItem;
