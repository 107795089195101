/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ListSection from '../ListSection';
import VehicleToggle from '../VehicleToggle';
import TextInput from '../TextInput';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import api from '../../services/api';

import { IProps, IPayload } from '../../interfaces/components/pinForm.interface';

const createInitialValue = (initialValue) => (acc, vehicle) => {
  // eslint-disable-next-line no-param-reassign
  acc[vehicle.id] = initialValue;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return acc;
};

const PinForm = ({
  id, perVehiclePinVehicles, postRoute, setComponentTree, singleSelect,
}: IProps) => {
  const navigate = useNavigate();
  const { setFlashErrorText, resetError } = useFlashErrorText();
  const { setAppLoading } = useAppLoading();

  const pinRequiringVehicles = perVehiclePinVehicles.pinRequiringVehicles.vehicles;

  // create state to keep track of pin values
  const initialState = pinRequiringVehicles.reduce(createInitialValue(''), {});
  const [pinValues, setPinValues] = useState(initialState);

  // create state to keep track of opening/close of for checkboxes or active radio if SS
  let initialVehicles;
  if (singleSelect) {
    initialVehicles = pinRequiringVehicles.reduce((acc, vehicle, i) => {
      if (i === 0) {
        acc[vehicle.id] = true;
      } else {
        acc[vehicle.id] = false;
      }
      return acc;
    }, {});
  } else {
    initialVehicles = pinRequiringVehicles.reduce(
      createInitialValue(true),
      {},
    );
  }
  const [vehiclesSelected, setVehiclesSelected] = useState(initialVehicles);

  const handleVehicleToggle = (activeVehicleId) => {
    if (singleSelect) {
      const vehicles = Object.keys(vehiclesSelected).reduce((acc, vehicleId) => {
        if (vehicleId === activeVehicleId) {
          acc[vehicleId] = true;
        } else {
          acc[vehicleId] = false;
        }
        return acc;
      }, {});
      setVehiclesSelected(vehicles);
    } else {
      setVehiclesSelected({
        ...vehiclesSelected,
        [activeVehicleId]: !vehiclesSelected[activeVehicleId],
      });
    }
  };

  const handlePinValue = (vehicleId) => (inputValue) => {
    setPinValues({
      ...pinValues,
      [vehicleId]: inputValue,
    });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const keyValue = e.key;
    if (/^\D$/.test(keyValue)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const payload: IPayload = {};

    payload.pins = pinRequiringVehicles.reduce(
      (pins, vehicle) => {
        // check if vehicle is selected
        if (vehiclesSelected[vehicle.id]) {
          if (pinValues[vehicle.id].length === 4) {
            // eslint-disable-next-line no-param-reassign
            pins[vehicle.id] = pinValues[vehicle.id];
          }
        }
        return pins;
      }, {});
    try {
      setAppLoading(true);

      const { data } = await api.postBackend(postRoute, payload);

      setAppLoading(false);
      setPinValues(initialState);
      resetError();

      if (data.routes) {
        navigate(data.routes.frontend);
      } else {
        setComponentTree(data);
      }
    } catch (err: any) {
      // clear forms
      setPinValues(initialState);
      setAppLoading(false);

      if (err.response && err.response.data) {
        // display flash error
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          console.log(`Session ID: ${err.response.data.sessionId}`);
        } else {
          // display page error
          setComponentTree(err.response.data);
        }
      }
    }
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit} id={id}>
      {pinRequiringVehicles.length && (
        <ListSection
          title={perVehiclePinVehicles.pinRequiringVehicles.title}
          className="pin-forms-container"
        >
          {pinRequiringVehicles.map(((vehicle, i) => {
            const hasVehicleError = vehicle && vehicle.error;
            return (
              <React.Fragment key={vehicle.id}>
                <VehicleToggle
                  checkedStatus={vehiclesSelected[vehicle.id]}
                  disabled={false}
                  handleChange={handleVehicleToggle}
                  id={vehicle.id}
                  inputType={singleSelect ? 'radio' : 'checkbox'}
                  name="vehicles"
                  vehicle={vehicle}
                />
                {vehiclesSelected[vehicle.id] && (
                  <>
                    {hasVehicleError && <p className="pin-error">{vehicle.error}</p>}
                    <TextInput
                      id={`${i}-pin`}
                      icon="/assets/images/icons/hash.svg"
                      handleChange={handlePinValue(vehicle.id)}
                      name={`${i}-pin`}
                      placeholder="PIN"
                      required
                      inputType="text"
                      value={pinValues[vehicle.id]}
                      additionalInputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        minLength: 4,
                        maxLength: 4,
                      }}
                      handleKeyDown={handleKeyDown}
                      wrapperClassName="pin-form"
                    />
                  </>
                )}
              </React.Fragment>
            );
          }))}
        </ListSection>
      )}
      {perVehiclePinVehicles.pinVerifiedVehicles.vehicles.length > 0 && (
        <ListSection
          title={perVehiclePinVehicles.pinVerifiedVehicles.title}
          className="pins-verified"
        >
          {perVehiclePinVehicles.pinVerifiedVehicles.vehicles.map(((vehicle) => (
            <VehicleToggle
              key={`verified-${vehicle.id}`}
              checkedStatus
              disabled
              handleChange={handleVehicleToggle}
              id={vehicle.id}
              inputType="checkbox"
              name="vehicles"
              status="disabled"
              vehicle={vehicle}
            />
          )))}
        </ListSection>
      )}
    </form>
  );
};

export default PinForm;
