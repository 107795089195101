import axios, { AxiosInstance, AxiosResponse } from 'axios';
// how do I type appConfig?
import appConfig from 'appConfig';
import defaultError from '../../payloads/default-error';

import { IApi, IResponseData } from '../../interfaces/services/api';

const apiInstance: AxiosInstance = axios.create({
  baseURL: appConfig.baseURL,
  // Allows cookies to be sent.
  withCredentials: true,
});

const netlifyInstance: AxiosInstance = axios.create({
  baseURL: appConfig.netlifyEdgeFunctionBaseUrl,
});

// if the page has refreshed or if we've left connect (ex: for an external
// oem auth flow) we'll need to reset the tokens on the request header
const storedCsrfToken = localStorage.getItem('c-token');
if (storedCsrfToken) {
  apiInstance.defaults.headers.common['X-CSRF-TOKEN'] = storedCsrfToken;
}
const storedScToken = localStorage.getItem('sc-token');
if (storedScToken) {
  apiInstance.defaults.headers.common['SC-TOKEN'] = storedScToken;
}

const api: IApi = {
  needSessionToken: () => appConfig.timedTokenEnabled && !apiInstance.defaults.headers.common['SC-TOKEN'],
  postBackend: async (route, payload) => {
    if (api.needSessionToken()) {
      await api.getSessionToken();
    }
    const result = await apiInstance.post(route, payload);

    return result;
  },
  getComponents: async (route, routeRequiresToken = true) => {
    if (routeRequiresToken && api.needSessionToken()) {
      await api.getSessionToken();
    }
    const result = await apiInstance.get(route);

    return result;
  },
  setCSRFHeader: (csrfToken) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    apiInstance.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
  },

  getInitialToken: async () => {
    const result = await netlifyInstance.get('/token');

    const initialToken = result.data as string;

    apiInstance.defaults.headers.common['SC-TOKEN'] = initialToken;
  },
  getSessionToken: async () => {
    try {
      const storedSessionId = localStorage.getItem('session-id') as string;
      const result = await netlifyInstance.get('/s-token', {
        params: {
          id: storedSessionId,
        },
      });

      const sessionToken = result.data as string;

      // save token in local storage so it's available when we return from an OEM auth flow
      localStorage.setItem('sc-token', sessionToken);
      apiInstance.defaults.headers.common['SC-TOKEN'] = sessionToken;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        error.response.data = defaultError(error.response?.status);
      }
      throw error;
    }
  },
  clearSessionToken: () => {
    localStorage.removeItem('sc-token');
    delete apiInstance.defaults.headers.common['SC-TOKEN'];
  },
};

apiInstance.interceptors.response.use((response: AxiosResponse) => {
  let csrfToken = '';
  const responseData = response.data as IResponseData;
  if (responseData && responseData.metadata?.csrfToken) {
    // save token in local storage so it's available when we return from an OEM auth flow
    csrfToken = responseData.metadata.csrfToken;
    localStorage.setItem('c-token', csrfToken);
  }
  if (csrfToken) {
    api.setCSRFHeader(csrfToken);
  }

  return response;
});

export default api;
