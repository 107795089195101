import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import appConfig from 'appConfig';

import {
  Authorize,
  BrandSelector,
  Grant,
  Login,
  OemRedirect,
  Preamble,
  RedirectToHome,
  RevokeOemAccess,
  Success,
  VinImport,
} from './pages';

import { IAppProps, IComponentTree } from './interfaces/app.interface';

import './scss/main.scss';
import { ThemeProvider } from './contexts/Theme';
import { SelectionsContextProvider } from './contexts/Selections';
import { ProvideFlashErrorText } from './contexts/FlashErrorText';
import { ProvideAppLoading } from './contexts/AppLoading';

const App = ({ payload }: IAppProps) => {
  const [isAuthorized, setIsAuthorized] = useState(Boolean(payload));
  const [preambleData, setPreambleData] = useState<IComponentTree | undefined>();

  const defaultPageTitle = 'Smartcar Connect';
  const defaultPageDescription = 'Connect your vehicle';

  const [componentTree, setComponentTree] = useState<IComponentTree>({
    metadata: {
      pageInfo: {
        id: 'connect',
        title: defaultPageTitle,
        description: defaultPageDescription,
      },
    },
    components: [],
  });

  useEffect(() => {
    if (payload) {
      setComponentTree(JSON.parse(payload) as IComponentTree);
    }
  }, [payload]);

  const style = {
    width: '100%',
  };

  const pageProps = {
    pageMetadata: componentTree.metadata.pageInfo,
    componentTree: componentTree.components,
    setComponentTree,
  };

  const previewWithValidReferrer = (
    document
    && document.referrer
    && document.referrer.includes(appConfig.previewAccessOrigin)
  );

  return (
    <div style={style}>
      <Helmet>
        <title>
          {componentTree.metadata.pageInfo.title || defaultPageTitle}
        </title>
        <meta
          name="description"
          content={componentTree.metadata.pageInfo.description || defaultPageDescription}
        />
      </Helmet>
      <ThemeProvider>
        <SelectionsContextProvider>
          <ProvideFlashErrorText>
            <ProvideAppLoading>
              <BrowserRouter>
                <Routes>
                  {/* preview routes - only accessible via dashboard iframe */}
                  {previewWithValidReferrer && (
                  <>
                    <Route
                      path="/preview/preamble"
                      element={<Preamble {...pageProps} isPreview />}
                    />
                    <Route
                      path="/preview/brand-selector"
                      element={<BrandSelector {...pageProps} isPreview />}
                    />
                    <Route
                      path="/preview/login"
                      element={<Login {...pageProps} isPreview />}
                    />
                    <Route
                      path="/preview/grant"
                      element={<Grant {...pageProps} isPreview />}
                    />
                  </>
                  )}
                  {/* standard routes  */}
                  <Route
                    path="/preamble"
                    element={(
                      <Preamble
                        {...pageProps}
                        preambleData={preambleData}
                        setPreambleData={setPreambleData}
                      />
                    )}
                  />
                  <Route
                    path="/brand-selector"
                    element={<BrandSelector {...pageProps} />}
                  />
                  <Route
                    path="/reauthenticate/login"
                    element={<Login {...pageProps} />}
                  />
                  <Route
                    path="/return/login"
                    element={<Login {...pageProps} />}
                  />
                  <Route
                    path="/login"
                    element={<Login {...pageProps} />}
                  />
                  <Route
                    path="/redirect"
                    element={<OemRedirect {...pageProps} />}
                  />
                  <Route
                    path="/authorize/redirect"
                    element={<OemRedirect {...pageProps} />}
                  />
                  <Route
                    path="/vin-import"
                    element={<VinImport {...pageProps} />}
                  />
                  <Route
                    path="/grant"
                    element={<Grant {...pageProps} />}
                  />
                  <Route
                    path="/revoke"
                    element={<RevokeOemAccess {...pageProps} />}
                  />
                  <Route
                    path="/update"
                    element={<RevokeOemAccess {...pageProps} />}
                  />
                  <Route
                    path="reauthenticate/revoke"
                    element={<RevokeOemAccess {...pageProps} />}
                  />
                  <Route
                    path="reauthenticate/redirect"
                    element={<OemRedirect {...pageProps} />}
                  />
                  <Route
                    path="reauthenticate/success"
                    element={<Success {...pageProps} />}
                  />
                  <Route
                    path="/oauth/authorize"
                    element={(
                      <Authorize
                        {...pageProps}
                        setPreambleData={setPreambleData}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                      />
                  )}
                  />
                  <Route
                    path="/oauth/reauthenticate"
                    element={(
                      <Authorize
                        {...pageProps}
                        isAuthorized={isAuthorized}
                        setIsAuthorized={setIsAuthorized}
                      />
                  )}
                  />

                  {/* for all other routes, redirect to the Smartcar homepage */}
                  <Route
                    path="/*"
                    element={<RedirectToHome />}
                  />
                </Routes>
              </BrowserRouter>
            </ProvideAppLoading>
          </ProvideFlashErrorText>
        </SelectionsContextProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;

App.defaultProps = {
  payload: '',
};
