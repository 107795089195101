import clsx from 'clsx';

import { IProps } from '../../interfaces/components/inlineStyledText.interface';

const InlineStyledText = ({ text }: IProps) => {
  const textArr = text.split('|*|');

  return textArr.map((textChunk) => {
    const textChunkSplit = textChunk.split('@@');

    if (textChunkSplit.length === 1) {
      return <span key={textChunk}>{textChunk}</span>;
    }
    return (
      <span key={textChunkSplit[1]} className={clsx(textChunkSplit[0])}>
        {textChunkSplit[1]}
      </span>
    );
  });
};

export default InlineStyledText;
