/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import exit from '../../assets/images/icons/exit.svg';

import { IProps } from '../../interfaces/components/modal.interface';

const Modal = ({ children, closeModal }: IProps) => {
  return (
    <div onClick={closeModal} className="modal-wrapper">
      <div onClick={(e) => e.stopPropagation()} className="modal">
        <div className="modal-content">
          <button type="button" onClick={closeModal} className="button-unstyled float-right">
            <img src={exit} alt="close icon" />
          </button>
          <div className="modal-content-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
