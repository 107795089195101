import { useTheme } from '../../contexts/Theme';

const LoginAnimation = () => {
  const { theme } = useTheme();

  const primary = theme === 'light' ? '#DBE4E8' : '#293235';
  const secondary = theme === 'light' ? '#293235' : '#DBE4E8';
  const background = theme === 'light' ? '#F9FDFF' : '#151D20';

  return (
    <div id="login-animation">
      <div id="dotted-line">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="612px"
          height="792px"
          viewBox="0 0 612 792"
          enableBackground="new 0 0 612 792"
          xmlSpace="preserve"
        >
          <path className="path" d="M1 0.999939C1 0.999939 90.5 1 144 0.999939C151.34 0.99993 155.784 1.87576 160.5 7.5C165.142 13.036 166.633 20.2765 166.5 27.5C166.373 34.3552 165.272 39.0771 160.5 44C155.679 48.9725 150.926 50.5 144 50.5C101.5 50.5 75 50.5 31.5 50.5C22.24 50.5 15.0549 51.9938 9 59C4.18706 64.5691 3.1382 70.6407 3 78C2.85496 85.7232 4.46059 91.1163 10 96.5C16.2512 102.575 31.5 102.5 31.5 102.5H166.5" fill="none" stroke={secondary} strokeMiterlimit="4" strokeWidth="2" />
          <path className="dashed" d="M1 0.999939C1 0.999939 90.5 1 144 0.999939C151.34 0.99993 155.784 1.87576 160.5 7.5C165.142 13.036 166.633 20.2765 166.5 27.5C166.373 34.3552 165.272 39.0771 160.5 44C155.679 48.9725 150.926 50.5 144 50.5C101.5 50.5 75 50.5 31.5 50.5C22.24 50.5 15.0549 51.9938 9 59C4.18706 64.5691 3.1382 70.6407 3 78C2.85496 85.7232 4.46059 91.1163 10 96.5C16.2512 102.575 31.5 102.5 31.5 102.5H166.5" fill="none" stroke={background} strokeWidth="3" />
        </svg>
      </div>
      <div id="cloud">
        <svg width="80" height="50" viewBox="0 0 68 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M56.1185 13.6102H53.2388C52.3839 10.3234 50.6176 7.34044 48.1396 5.00042C45.6622 2.66034 42.5732 1.05705 39.2232 0.37264C35.8737 -0.311775 32.3977 -0.0497945 29.1908 1.12874C25.9835 2.30727 23.1733 4.35512 21.0804 7.03964C8.4183 5.00042 6.52452 13.5547 6.17924 16.9322C6.17924 16.9322 -1.62419 16.9322 0.307384 28.4982C1.0539 32.9721 5.71449 36.2941 14.5981 36.2941C21.0804 36.2941 32.1315 36.2966 35.5511 36.2941H56.1185C59.1489 36.2941 62.0554 35.0989 64.1981 32.9721C66.3409 30.8448 67.5446 27.9603 67.5446 24.9519C67.5446 21.9439 66.3409 19.059 64.1981 16.9322C62.0554 14.8054 59.1489 13.6102 56.1185 13.6102Z" fill={primary} />
          <path d="M33.6987 7.56152C27.3369 7.56152 22.1788 12.72 22.1788 19.0822C22.1788 25.4444 27.3369 30.6034 33.6987 30.6034C40.0605 30.6034 45.2191 25.4444 45.2191 19.0822C45.2191 12.72 40.0605 7.56152 33.6987 7.56152ZM33.6987 10.4419C37.4479 10.4419 40.6149 12.8561 41.8101 16.2023H25.5878C26.7829 12.8561 29.9499 10.4419 33.6987 10.4419ZM33.6987 20.5224C33.4139 20.5224 33.1356 20.4382 32.8987 20.2799C32.6618 20.1216 32.4773 19.8968 32.3684 19.6337C32.2596 19.3705 32.2308 19.0807 32.2863 18.8014C32.3422 18.5221 32.4793 18.2656 32.6805 18.0639C32.8821 17.8628 33.1387 17.7257 33.4179 17.6697C33.6972 17.6143 33.987 17.643 34.2501 17.7519C34.5132 17.8608 34.7381 18.0453 34.8963 18.2822C35.0546 18.5191 35.1388 18.7974 35.1388 19.0822C35.1388 19.2717 35.102 19.4588 35.0294 19.6337C34.9573 19.8086 34.851 19.9674 34.7174 20.101C34.5838 20.2346 34.425 20.3409 34.2501 20.413C34.0752 20.4856 33.8882 20.5224 33.6987 20.5224ZM25.059 19.0822C28.9937 19.0822 32.1799 22.874 32.2475 27.5768C28.1751 26.8791 25.059 23.3494 25.059 19.0822ZM35.1504 27.5768C35.2174 22.874 38.4041 19.0822 42.3388 19.0822C42.3388 23.3494 39.2227 26.8791 35.1504 27.5768Z" fill={secondary} />
        </svg>
      </div>
      <div id="phone">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="21" cy="21" r="21" fill={primary} />
          <path d="M26 11H16C14.8954 11 14 11.8954 14 13V29C14 30.1046 14.8954 31 16 31H26C27.1046 31 28 30.1046 28 29V13C28 11.8954 27.1046 11 26 11Z" stroke={secondary} strokeLinecap="round" strokeLinejoin="round" />
          <path d="M21 27H21.01" stroke={secondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <div id="vehicle">
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.1789 44.3606C34.4279 44.3606 44.3577 34.4302 44.3577 22.1803C44.3577 9.93047 34.4279 0 22.1789 0C9.92981 0 0 9.93047 0 22.1803C0 34.4302 9.92981 44.3606 22.1789 44.3606Z" fill={primary} />
          <path d="M33.8267 20.9388L31.7379 19.985L29.8945 17.0436C29.3617 16.1906 28.4236 15.6739 27.4165 15.6795H19.5627C18.6513 15.6795 17.7884 16.1004 17.2339 16.8248L14.8522 19.9421L10.8827 21.0295C9.87556 21.3062 9.17743 22.2282 9.17743 23.2727V24.7916C9.17743 26.0755 10.2007 27.1361 11.4845 27.1361H11.8258C12.2361 28.719 13.8506 29.6672 15.4329 29.2569C16.4723 28.9852 17.2873 28.1751 17.554 27.1361H26.5057C26.916 28.719 28.5305 29.6672 30.1128 29.2569C31.1521 28.9852 31.9672 28.1751 32.2334 27.1361H32.873C34.1574 27.1361 35.1801 26.0755 35.1801 24.7916V23.0595C35.1857 22.1486 34.6529 21.3173 33.8267 20.9388ZM14.6869 28.2764C13.6424 28.2764 12.7956 27.429 12.7956 26.385C12.7956 25.3405 13.6424 24.4932 14.6869 24.4932C15.7313 24.4932 16.5786 25.3405 16.5786 26.385C16.5842 27.424 15.7368 28.2714 14.6869 28.2764ZM29.3668 28.2764C28.3223 28.2764 27.4755 27.429 27.4755 26.385C27.4755 25.3405 28.3223 24.4932 29.3668 24.4932C30.4112 24.4932 31.2585 25.3405 31.2585 26.385C31.2641 27.424 30.4167 28.2714 29.3668 28.2764ZM32.873 26.0705H32.308C32.1326 24.4453 30.6672 23.2727 29.0416 23.4542C27.662 23.6034 26.5747 24.6958 26.4255 26.0705H17.6286C17.4527 24.4453 15.9874 23.2727 14.3622 23.4542C12.9821 23.6034 11.8948 24.6958 11.7456 26.0705H11.4795C10.7814 26.0705 10.238 25.4898 10.238 24.7916V23.2727C10.2324 22.7026 10.611 22.2071 11.1594 22.0523L15.3104 20.9176C15.4223 20.8853 15.5236 20.8218 15.5927 20.7255L18.0757 17.4695C18.4331 17.0113 18.982 16.7452 19.5627 16.7452H27.4165C28.0562 16.7396 28.658 17.0647 28.9937 17.6082L30.9228 20.6882C30.9762 20.7789 31.0614 20.848 31.1521 20.8909L33.3791 21.9086C33.8267 22.1163 34.1145 22.564 34.109 23.0595V24.7916H34.1145C34.1145 25.4898 33.5712 26.0705 32.873 26.0705Z" fill={secondary} />
          <path d="M33.4296 21.8269L31.2001 20.8091C31.124 20.7754 31.0599 20.7199 31.0151 20.6493L29.083 17.5693C28.7231 16.9951 28.091 16.6483 27.413 16.6544H19.5617C18.9488 16.6524 18.3701 16.9362 17.9956 17.4216L15.511 20.6771C15.4556 20.7512 15.377 20.8051 15.2872 20.8298L11.1347 21.9661C10.5415 22.1314 10.1327 22.6733 10.1367 23.2893V24.8077C10.1367 25.5638 10.728 26.1924 11.4841 26.1924H11.8455C11.9523 24.7875 13.0688 23.6709 14.4737 23.5645C16.0443 23.4451 17.4139 24.6216 17.5333 26.1924H26.5254C26.6322 24.7875 27.7487 23.6709 29.1536 23.5645C30.7242 23.4451 32.0938 24.6216 32.2133 26.1924H32.8746C33.6307 26.1924 34.2214 25.5638 34.2214 24.8077V23.0741C34.2255 22.5387 33.916 22.0507 33.4296 21.8269Z" fill={primary} />
        </svg>
      </div>
    </div>
  );
};

export default LoginAnimation;
