import clsx from 'clsx';

import { IProps } from '../../interfaces/components/overlapImage.interface';

const OverlapImage = ({ imageFront, imageBack }: IProps) => {
  const images = [imageFront, imageBack];
  return (
    <div className="overlap-image-container">
      {images.map((image, i) => {
        return (
          <div key={image.url} className={clsx('overlap-image-background', i === 0 && 'overlap-image-first')}>
            <img src={image.url} alt={image.alt} className="overlap-image" />
          </div>
        );
      })}
    </div>
  );
};

export default OverlapImage;
