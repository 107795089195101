import clsx from 'clsx';

import { IProps } from '../../interfaces/components/paragraph.interface';

const Paragraph = ({ children, className, text }: IProps) => {
  return <p className={clsx(className)}>{children || text}</p>;
};

Paragraph.defaultProps = {
  className: '',
};

export default Paragraph;
