import clsx from 'clsx';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { IProps } from '../../interfaces/components/tooltip.interface';

const Tooltip = ({
  children, tooltipContent, wrapperClassName,
}: IProps) => {
  let content: React.ReactElement | undefined;
  if (typeof tooltipContent === 'string') {
    content = <div>{tooltipContent}</div>;
  } else if (tooltipContent.url) {
    content = (
      <img
        src={tooltipContent.url}
        alt={tooltipContent.alt}
        className={tooltipContent.className}
      />
    );
  }

  if (!content) {
    return null;
  }

  return (
    <Tippy content={content} className={clsx(wrapperClassName)}>
      {children}
    </Tippy>
  );
};

export default Tooltip;
