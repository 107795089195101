import clsx from 'clsx';
import InlineLinks from '../InlineLinks';

import { formatIconPath } from '../../contexts/Theme';
import { IProps } from '../../interfaces/components/footer.interface';

const Footer = ({
  text, recaptchaDisclaimer, disableLinks, isSimulatedOemPage, logoText, className,
}: IProps) => {
  return (
    isSimulatedOemPage ? (
      <div className={clsx('footer', className)}>
        <div className="footer-text">
          {text}
        </div>
      </div>
    ) : (
      <div className={clsx('footer', className)}>
        {text && (
          <div className="footer-text">
            {text}
          </div>
        )}
        <div className="footer-logo">
          <p>{logoText}</p>
          <img src={formatIconPath('/assets/images/icons/smartcar-footer.svg')} width="66" alt="smartcar logo" />
        </div>
        {recaptchaDisclaimer && (
          <div className="footer-text">
            <InlineLinks text={recaptchaDisclaimer} disabled={disableLinks} />
          </div>
        )}
      </div>
    )
  );
};

Footer.defaultProps = {
  logoText: 'Powered by',
  text: '',
  disableLinks: false,
  isSimulatedOemPage: false,
};

export default Footer;
