/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createContext, useMemo, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import api from '../../services/api';

import { useFlashErrorText } from '../../contexts/FlashErrorText';

import {
  IInitialFormState, IFormContext, IProps,
} from '../../interfaces/components/form.interface';
import { useAppLoading } from '../../contexts/AppLoading';

export const FormContext = createContext<IFormContext | undefined>(undefined);

const Form = (props: IProps) => {
  const {
    autoSubmit,
    children,
    childrenComponents,
    className,
    id,
    postRoute,
    setComponentTree,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { setLoginPageLoading, setAppLoading } = useAppLoading();
  const { setFlashErrorText, setMetadataText } = useFlashErrorText();

  // we use the childrenComponents (part of the component tree from the backend) to initialize
  // state since we can receive initial values from the backend. also if validators are used
  // for inputs we can attach them to the state here
  const formInitialState: IInitialFormState = {};
  const getInitialFormState = (childComponents) => {
    for (let i = 0; i < childComponents.length; i += 1) {
      const component = childComponents[i];
      if (component.componentType === 'textInput' || component.componentType === 'select') {
        formInitialState[component.name] = {
          value: component.initialValue || '',
        };
        if (component.validator) {
          formInitialState[component.name].validator = component.validator;
        }
      } else if (component.componentType === 'toggleInput' || component.componentType === 'vehicleToggle') {
        if (component.inputType === 'radio') {
          // select the first radio button available
          formInitialState.selectedRadio = formInitialState.selectedRadio
            ? formInitialState.selectedRadio
            : component.value;
        } else if (component.inputType === 'checkbox') {
          if (!formInitialState[component.name]) {
            formInitialState[component.name] = {};
          }
          if (component.value) {
            formInitialState[component.name][component.value] = Boolean(component.defaultChecked);
          }
        }
      } else if (component.componentType === 'hidden') {
        formInitialState[component.name] = component.value;
      } else if (component.componentType === 'reCAPTCHA') {
        const reCAPTCHARef = useRef();
        formInitialState.reCAPTCHARef = reCAPTCHARef;
        formInitialState.make = component.make;
      } else if (component.childrenComponents) {
        getInitialFormState(component.childrenComponents);
      }
    }
  };
  getInitialFormState(childrenComponents);

  const [formState, setFormState] = useState(formInitialState);
  const [formValidated, setFormValidated] = useState(false);

  const submitFormData = async () => {
    // reset any flash errors
    setFlashErrorText('');

    try {
      const formData = {
        ...formState,
      };

      if (formData.reCAPTCHARef) {
        const token = await formData.reCAPTCHARef.current?.executeAsync();

        // replace reCAPTCHARef property with recaptchaToken for consumption on backend
        delete formData.reCAPTCHARef;
        formData.recaptchaToken = token;
      }

      const { data } = await api.postBackend(postRoute, formData);

      setFormValidated(false);
      setAppLoading(false);
      setLoginPageLoading(false);

      // need to use window.location.href here bc we are redirecting outside of Connect
      if (data.redirect) {
        window.location.href = data.redirect;
      } else if (data.routes) {
        navigate(data.routes.frontend);
      } else {
        setComponentTree(data);
      }
    } catch (err: any) {
      // clear forms
      setFormState(formInitialState);
      setFormValidated(false);
      setLoginPageLoading(false);
      setAppLoading(false);

      if (err.response && err.response.data) {
      // display flash error
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
          console.log(`Session ID: ${err.response.data.sessionId}`);

          if (err.response.data.metadata) {
            setMetadataText(err.response.data.metadata);
          }
        } else {
        // display page error
          setFlashErrorText('');
          setComponentTree(err.response.data);
        }
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // This logic is for rendering the loading animation page specifically for the Login Page after
    // a user enters their credentials.
    if ((location.pathname === '/login' || '/return/login') && ('password' in formState)) {
      setLoginPageLoading(true);
    } else {
      setAppLoading(true);
    }

    e.preventDefault();

    // validate form data if validators present
    // currently there are no validators implemented
    const allValidated = Object.keys(formState).every((name) => {
      const item = formState[name];
      if (item.validator) {
        return item.validator(item.value);
      }
      return true;
    });

    if (!allValidated) {
      setFormValidated(false);

      return;
    }

    setFormValidated(true);
    await submitFormData();
  };

  const contextValue: IFormContext = useMemo(() => ({
    formState,
    setFormState,
    submitFormData: autoSubmit ? submitFormData : null,
    formValidated,
  }), [formState, formValidated]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form className={clsx(className)} id={id} onSubmit={handleSubmit}>
      <FormContext.Provider value={contextValue}>
        {children}
      </FormContext.Provider>
    </form>
  );
};

export default Form;
