import clsx from 'clsx';

import { IProps } from '../../interfaces/components/list.interface';

const List = ({ children, className }: IProps) => {
  return (
    <ul className={clsx(className)}>
      {children}
    </ul>
  );
};

List.defaultProps = {
  className: '',
};

export default List;
