import { FC, SVGProps } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

import { IProps } from '../../interfaces/components/link.interface';

import Launch from '../../assets/images/icons/launch.svg?component';

const iconAdornmentMap = {
  launch: Launch,
};

const Link = (props: IProps) => {
  const {
    className, id, newTab, path, text, textIconAdornment,
  } = props;

  let openingAttributes = {};

  if (newTab) {
    openingAttributes = {
      target: '_blank',
      rel: 'noreferrer noopener',
    };
  }

  const getText = () => {
    if (textIconAdornment) {
      const Icon = iconAdornmentMap[textIconAdornment] as FC<SVGProps<SVGSVGElement>>;
      return (
        <div className="flex align-center justify-center">
          <span>{text}</span>
          <Icon className="fill-primary m-l-xs" />
        </div>
      );
    }

    return text;
  };

  const classNameAndId = {
    className: clsx('link', className),
    id,
  };

  return path.startsWith('/') ? (
    <RouterLink
      to={path}
      {...classNameAndId}
    >
      {getText()}
    </RouterLink>
  ) : (
    <a
      href={path}
      {...classNameAndId}
      {...openingAttributes}
    >
      {getText()}
    </a>
  );
};

Link.defaultProps = {
  newTab: true,
};

export default Link;
