/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';
import api from '../../../../services/api';

import { useFlashErrorText } from '../../../../contexts/FlashErrorText';

import { IBrandList, IActionMap } from '../../../../interfaces/components/brandList.interface';
import Button from '../../../Button';
import Modal from '../../../Modal';
import { IMake, IUnsupportedMake } from '../../../../interfaces/components/brandSelector.interface';
import BrandListItem from '../BrandListItem';
import Footer from '../../../Footer';

const getMatchingOems = (makes: IMake[] | IUnsupportedMake[], searchValue) => {
  return makes.filter((oem) => {
    const matchesDisplayName = oem.displayName
      .toLowerCase()
      .startsWith(searchValue.toLowerCase());
    if (matchesDisplayName) {
      return true;
    }

    // otherwise check additional acceptable names for the make
    return oem.acceptedNames && oem.acceptedNames.find((acceptedName) => (
      acceptedName.toLowerCase().startsWith(searchValue.toLowerCase())
    ));
  });
};

const removeDuplicatesFromSearch = (makes: IMake[] | IUnsupportedMake[]) => {
  const makeFilteredResults = makes.filter((oem, index) => {
    return makes.findIndex((item) => oem.make === item.make) === index;
  });

  return makeFilteredResults;
};

const BrandList = (props: IBrandList) => {
  const {
    actionButtons,
    footerContent,
    smartcarUnsupportedMakes,
    supportedMakes,
    appConfigUnsupportedMakes,
    noResults,
    postRoute,
    setComponentTree,
    searchValue,
    modalContent,
    resetSearch,
    disabled,
  } = props;
  const navigate = useNavigate();
  const { setFlashErrorText } = useFlashErrorText();

  const [unsupportedMake, setUnsupportedMake] = useState('');
  const [appConfigUnsupportedMake, setAppConfigUnsupportedMake] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const filterOems = (filterValue: string) => {
    const supportedOems = getMatchingOems(supportedMakes, filterValue) as IMake[];
    const unsupportedOems = getMatchingOems(smartcarUnsupportedMakes, filterValue);
    const appConfigUnsupportedOems = getMatchingOems(
      appConfigUnsupportedMakes, filterValue,
    ) as IMake[];

    const searchResults = [...supportedOems, ...unsupportedOems, ...appConfigUnsupportedOems];
    return removeDuplicatesFromSearch(searchResults);
  };
  const listedOems = searchValue ? filterOems(searchValue) : supportedMakes;

  const exitFlow = async (route: string) => {
    const payload = { backToApplication: true, incompatibleMake: '', brandSelectorExit: true };
    if (unsupportedMake) {
      payload.incompatibleMake = unsupportedMake;
    } else if (searchValue) {
      payload.incompatibleMake = searchValue;
    }

    try {
      const { data } = await api.postBackend(route, payload);

      // need to use window.location.href = here bc we're redirecting outside of Connect
      if (data.redirect) {
        window.location.href = data.redirect;
      }
    } catch (err: any) {
      if (err.response && err.response.data) {
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          console.log(`Session ID: ${err.response.data.sessionId}`);
        } else {
        // display page error
          setComponentTree(err.response.data);
        }
      }
    }
  };

  const selectBrand = async (make: string) => {
    // check if make is unsupported, or
    if (smartcarUnsupportedMakes.some((item) => item.make === make)
    ) {
      setUnsupportedMake(make);
      setModalOpen(true);
      return;
    }

    // check if make is allowed for this application
    if (appConfigUnsupportedMakes
      && appConfigUnsupportedMakes.some((item) => item.make === make)) {
      setAppConfigUnsupportedMake(make);
      setModalOpen(true);
      return;
    }

    const payload = { selectedBrand: make };
    try {
      const { data } = await api.postBackend(postRoute, payload);

      if (data.routes) {
        navigate(data.routes.frontend);
      } else if (data.components) {
        setComponentTree(data.components);
      }
    } catch (err: any) {
      if (err.response && err.response.data) {
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          console.log(`Session ID: ${err.response.data.sessionId}`);
        } else {
        // display page error
          setComponentTree(err.response.data);
        }
      }
    }
  };

  const actionMap: IActionMap = {
    modal: () => setModalOpen(true),
    backToApplication: () => exitFlow('/oauth/brand-selector'),
    backToBrandSelector: () => {
      resetSearch();
      setUnsupportedMake('');
      setAppConfigUnsupportedMake('');
      setModalOpen(false);
    },
  };

  const getModalText = (): string => {
    // This renders if user clicks `I don't see my brand`. It shows generic text without brandName
    if (!unsupportedMake
      && !appConfigUnsupportedMake
      && modalOpen) return modalContent.brandIncompatible.text;

    // displays text in modal for an unsupportedMake with smartcar
    if (unsupportedMake) {
      const displayName = smartcarUnsupportedMakes
        .find((item) => item.make === unsupportedMake)?.displayName
      || unsupportedMake;

      const { template, placeholder } = modalContent.brandIncompatible.textToInterpolate;
      // replace placeholder
      return template.split(placeholder).join(displayName);
    }

    // displays text in modal for an unsupportedMake with a specific application
    const oemMake = appConfigUnsupportedMakes
      .find((item) => item.make === appConfigUnsupportedMake);
    const { template, placeholder } = modalContent.brandNotSupported.textToInterpolate;
    // replace placeholder
    return template.split(placeholder).join(oemMake?.displayName);
  };

  return (
    <div className="brand-list flex-column flex-space-between">
      {listedOems.length > 0 ? (
        <div className="results">
          {listedOems.map(({ logo, displayName, make }) => (
            <BrandListItem
              key={make}
              logo={logo}
              displayName={displayName}
              make={make}
              selectBrand={selectBrand}
              disabled={disabled}
            />
          ))}
        </div>
      ) : (
        <div className="no-results">
          <img src={noResults.image.url} alt={noResults.image.alt} />
          <p>{noResults.text}</p>
        </div>

      )}
      <div>
        {actionButtons.map(({
          action, additionalClassNames, elementAttributes, text,
        }) => {
          return (
            <Button
              key={text}
              additionalClassNames={additionalClassNames}
              elementAttributes={elementAttributes}
              text={text}
              handleClick={actionMap[action]}
              disabled={disabled}
            />
          );
        })}
        <div className="m-b-xl">
          <Footer {...footerContent} />
        </div>
      </div>
      <div className="stick-to-bottom" />
      {modalOpen && (
        <Modal closeModal={() => {
          setModalOpen(false);
          setUnsupportedMake('');
          setAppConfigUnsupportedMake('');
        }}
        >
          <img
            src={modalContent.image.url}
            alt={modalContent.image.alt}
          />
          <p className="large emphasized m-none m-t-med primary center">{getModalText()}</p>
          {modalContent.actionButtons.map((button) => (
            <Button
              key={button.elementAttributes.id}
              handleClick={actionMap[button.action]}
              additionalClassNames={button.additionalClassNames}
              elementAttributes={button.elementAttributes}
              text={button.text}
            />
          ))}
        </Modal>
      )}
    </div>
  );
};

export default BrandList;
