import clsx from 'clsx';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import exit from '../../assets/images/icons/exit.svg';

import { IProps } from '../../interfaces/components/modal.interface';

const Modal = ({
  children, className, closeModal, showModalContent = true,
}: IProps) => {
  return (
    <div onClick={closeModal} className={clsx('modal-wrapper', className)}>
      <div onClick={(e) => e.stopPropagation()} className={clsx('modal', showModalContent ? 'show' : 'hide')}>
        <div className="modal-content">
          <button type="button" onClick={closeModal} className="button-unstyled float-right">
            <img src={exit} alt="close icon" />
          </button>
          <div className="modal-content-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
