/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import * as ReactDOMClient from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import appConfig from 'appConfig';
import App from './App';

Sentry.init({
  dsn: appConfig.sentry.DSN,
  integrations: [new BrowserTracing()],

  // To stay under current Sentry quota of 100K transactions
  tracesSampleRate: 0.25,
});

const container = document.getElementById('root')!;

// Create a root.
const root = ReactDOMClient.createRoot(container);

// Initial render: Render an element to the root.
root.render(<App payload="" />);
