/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import Chevron from '../../assets/images/icons/chevron.svg?component';
import LogOut from '../../assets/images/icons/log-out.svg?component';

import { useAppLoading } from '../../contexts/AppLoading';

import { IProps } from '../../interfaces/components/accountMenu.interface';

const AccountMenu = ({ hideUsername, username, switchAccountText }: IProps) => {
  const { appLoading } = useAppLoading();

  if (hideUsername) {
    return (
      <button
        type="submit"
        className="button button-text-link button-auto-width underline no-spinner"
        id="logout-button"
        disabled={appLoading}
      >
        {switchAccountText}
      </button>
    );
  }

  const [menuOpen, setMenuOpen] = useState(false);
  const menu = useRef<HTMLDivElement>(null);

  const handleClick = (e) => {
    if (menu.current && !menu.current.contains(e.target)) {
      setMenuOpen(false);
    }
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, { capture: true });
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  return (
    <div className="button-menu-container" onClick={() => setMenuOpen(!menuOpen)} ref={menu} id="account-menu">
      <button
        type="button"
        className="button button-menu button-username flex-space-between align-center"
      >
        <span className="button-username-text m-l-xs">{username}</span>
        <Chevron className={clsx('svg-fill-only m-l-xs m-r-xs', menuOpen && 'rotate-180')} />
      </button>
      {menuOpen && (
        <div className="menu-logout" onClick={stopPropagation}>
          <button
            type="submit"
            className="button button-menu button-logout flex align-center no-spinner"
            id="logout-button"
            disabled={appLoading}
          >
            <LogOut className="svg-stroke-only m-l-sm m-r-xs" />
            <span className="m-r-sm">{switchAccountText}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default AccountMenu;
