import clsx from 'clsx';

import { IProps } from '../../interfaces/components/list.interface';

const OrderedList = ({ children, className }: IProps) => {
  return (
    <ol className={clsx(className)}>
      {children}
    </ol>
  );
};

OrderedList.defaultProps = {
  className: '',
};

export default OrderedList;
