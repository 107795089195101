const codeMap = {
  429: 'Too many requests',
  500: 'Internal Server Error',
};

/**
 * This error payload is for when an error occurs before the first request to connect-backend
 * (for example, when there is a rate limiting error from the Netlify Edge Functions for the
 * token endpoints). We use/store this page payload here because it doesn't make sense to make
 * an additional request to connect-backend to get an error page payload.
 *
 * If the request is erroring from connect-backend, connect-backend will generate an error page
 * payload and this function will not be used.
 *
 * @param code status code of the error
 * @returns page paylaod
 */
const error = (code: number | undefined) => {
  return {
    metadata: {
      pageInfo: {
        title: 'Error',
        description: 'Error',
        id: 'error',
        theme: 'light',
      },
    },
    components: [
      {
        componentType: 'header',
        image: {
          url: '/assets/images/icons/error.svg',
          alt: 'red x',
          className: 'header-image-left',
        },
        title: {
          text: "Oops, something's not right.",
          className: 'left header-m-b-med',
        },
        className: 'left border-none m-b-sm',
        childrenComponents: [
          {
            componentType: 'paragraph',
            text: 'If the problem persists, please contact support.',
            className: 'on-surface-secondary title-small',
            reactKey: '286e523a-7c0a-4f41-a871-2c6aa4387e20',
          },
        ],
        reactKey: '667ab3cf-e762-49b5-a77d-8565e0221001',
      },
      (code && codeMap[code]
        ? {
          componentType: 'paragraph',
          text: `${code}: ${codeMap[code] as string}`,
          className: 'small center m-t-lg',
          reactKey: '7fa538e9-91f7-4c93-b70f-5a7a88537b9f',
        } : {}),
    ],
  };
};

export default error;
