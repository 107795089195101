/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../Button';
import ToggleInput from '../ToggleInput';
import CustomBulletListItem from '../CustomBulletListItem';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import api from '../../services/api';

import { IProps } from '../../interfaces/components/oemPermissionsForm.interface';

const createInitialValue = (initialValue) => (acc, permission) => {
  // eslint-disable-next-line no-param-reassign
  acc[permission.name] = initialValue;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return acc;
};

const OemPermissionsForm = ({
  permissions,
  selectablePermissions,
  allowButton,
  selectAllButton,
  postRoute,
  setComponentTree,
}: IProps) => {
  const navigate = useNavigate();
  const { setFlashErrorText, resetError } = useFlashErrorText();
  const { appLoading, setAppLoading } = useAppLoading();

  const initialPermissions = permissions.reduce(createInitialValue(true), {});
  const [selectedPermissions, setSelectedPermissions] = useState(initialPermissions);

  const handlePermissionToggle = (permissionName: string) => {
    setSelectedPermissions({
      ...selectedPermissions,
      [permissionName]: !selectedPermissions[permissionName],
    });
  };

  const selectAllCheckboxes = () => {
    setSelectedPermissions(initialPermissions);
  };
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      setAppLoading(true);

      const { data } = await api.postBackend(postRoute, selectedPermissions);

      resetError();
      setAppLoading(false);

      if (data.routes) {
        navigate(data.routes.frontend);
      } else {
        setComponentTree(data);
      }
    } catch (err: any) {
      setAppLoading(false);
      if (err.response && err.response.data) {
        // display flash error
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          console.log(`Session ID: ${err.response.data.sessionId}`);
        } else {
          // display page error
          setComponentTree(err.response.data);
        }
      }
    }
  };

  const renderPermissions = permissions.map(({ name, displayName, description = '' }) => (
    <React.Fragment key={name}>
      {selectablePermissions ? (
        <ToggleInput
          checkedStatus={selectedPermissions[name]}
          handleChange={handlePermissionToggle}
          inputType="checkbox"
          id={name}
          name="permissions"
          label={{ text: displayName }}
          value={name}
          disabled={false}
          status=""
        />
      ) : (
        <CustomBulletListItem
          image={{
            url: '/assets/images/icons/check.svg',
            alt: '',
          }}
          title={{ text: displayName }}
          description={{ text: description }}
        />
      )}
    </React.Fragment>
  ));

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit}>
      {selectAllButton && (
        <Button
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          handleClick={selectAllCheckboxes}
          additionalClassNames={selectAllButton.additionalClassNames}
          text={selectAllButton.text}
          elementAttributes={{
            id: selectAllButton.id,
            type: 'button',
          }}
          disabled={appLoading}
        />
      )}
      {renderPermissions}
      <Button
        additionalClassNames={allowButton.additionalClassNames}
        elementAttributes={{
          id: allowButton.id,
          type: 'submit',
        }}
        text={allowButton.text}
      />
    </form>
  );
};

export default OemPermissionsForm;
