import { useState } from 'react';
import {
  IBrandSelector,
} from '../../interfaces/components/brandSelector.interface';
import { BrandList } from './components';
import BrandSearch from './components/BrandSearch';

const BrandSelector = ({
  actionButtons,
  footerContent,
  smartcarUnsupportedMakes,
  supportedMakes,
  appConfigUnsupportedMakes,
  searchBar,
  modalContent,
  noResults,
  postRoute,
  disabled,
  setComponentTree,
}: IBrandSelector) => {
  const [searchValue, setSearchValue] = useState('');
  const resetSearch = () => setSearchValue('');

  return (
    <div className="search-results">
      <BrandSearch
        searchBar={searchBar}
        searchValue={searchValue}
        handleChange={setSearchValue}
        resetSearch={resetSearch}
        disabled={disabled}
      />
      <BrandList
        actionButtons={actionButtons}
        footerContent={footerContent}
        smartcarUnsupportedMakes={smartcarUnsupportedMakes}
        supportedMakes={supportedMakes}
        appConfigUnsupportedMakes={appConfigUnsupportedMakes}
        modalContent={modalContent}
        resetSearch={resetSearch}
        noResults={noResults}
        postRoute={postRoute}
        setComponentTree={setComponentTree}
        searchValue={searchValue}
        disabled={disabled}
      />
    </div>
  );
};

export default BrandSelector;
