import InlineLinks from '../InlineLinks';

import { IProps } from '../../interfaces/components/modeBanner.interface';

const ModeBanner = ({ text }: IProps) => {
  return (
    <div className="mode-banner">
      <div>
        <InlineLinks text={text} />
      </div>
    </div>
  );
};

export default ModeBanner;
