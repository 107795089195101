import { IProps } from '../../interfaces/components/loginLoadingComponent.interface';
import { useAppLoading } from '../../contexts/AppLoading';

const LoginLoadingComponent = (props: IProps) => {
  const {
    children,
  } = props;
  const { loginPageLoading } = useAppLoading();

  return (
    <div className={loginPageLoading ? 'login-loading-container' : 'hide-login-loading-container'}>
      <div className="login-loading-center">
        {children}
      </div>
    </div>
  );
};

export default LoginLoadingComponent;
