import { formatIconPath } from '../../contexts/Theme';
import { IProps } from '../../interfaces/components/customBulletListItem.interface';

const CustomBulletListItem = ({ image, title, description }: IProps) => {
  return (
    <li className="list-item list-item-custom-bullet">
      <div className="flex align-center">
        <span>
          <img
            src={formatIconPath(image.url)}
            alt={image.alt}
            className="block"
          />
        </span>
        <span className="p-l-sm list-item-text">{title.text}</span>
      </div>
      {description && <p className="list-item-indented-text">{description.text}</p>}
    </li>
  );
};

CustomBulletListItem.defaultProps = {
  description: null,
};

export default CustomBulletListItem;
