import errorHandler from '../../../../services/sentry';

import { formatIconPath } from '../../../../contexts/Theme';
import { IBrandListItem } from '../../../../interfaces/components/brandList.interface';

const BrandListItem = ({
  logo, displayName, make, selectBrand, disabled,
}: IBrandListItem) => {
  const handleClick = () => {
    selectBrand(make)
      .catch((e: Error | string) => errorHandler(e));
  };

  return (
    <button
      key={make}
      type="button"
      id={make}
      className="brand-list-item"
      onClick={handleClick}
      disabled={disabled}
    >
      <div className="brand-logo-container">
        <img src={formatIconPath(logo)} alt={`${displayName} logo`} className="brand-logo" />
      </div>
      <div className="brand-name">{displayName}</div>
    </button>
  );
};

export default BrandListItem;
