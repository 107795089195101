import { createContext, useContext, useState } from 'react';

import { IAppLoadingContext, IProps } from '../interfaces/contexts/appLoading.interface';

export const AppLoadingContext = createContext<IAppLoadingContext | undefined>(undefined);

const useProvideAppLoading = () => {
  const [appLoading, setAppLoading] = useState(false);
  // Specifically for the login page after credentials are submitted
  const [loginPageLoading, setLoginPageLoading] = useState(false);

  return {
    appLoading,
    setAppLoading,
    loginPageLoading,
    setLoginPageLoading,
  };
};

export const ProvideAppLoading = ({ children }: IProps) => {
  const appLoadingValue = useProvideAppLoading();

  return (
    <AppLoadingContext.Provider value={appLoadingValue}>
      {children}
    </AppLoadingContext.Provider>
  );
};

export const useAppLoading = () => {
  const context = useContext(AppLoadingContext);

  if (context === undefined) {
    throw new Error('useAppLoading must be used within the ProvideAppLoading provider');
  }

  return context;
};
