import { createContext, useContext, useState } from 'react';

import { IFlashErrorTextContext, IProps } from '../interfaces/contexts/flashErrorText.interface';

export const FlashErrorTextContext = createContext<IFlashErrorTextContext | undefined>(undefined);

const useProvideFlashErrorText = () => {
  const [flashErrorText, setFlashErrorText] = useState('');
  const [metadataText, setMetadataText] = useState('');

  const resetError = () => {
    setFlashErrorText('');
    setMetadataText('');
  };

  return {
    flashErrorText,
    metadataText,
    setFlashErrorText,
    setMetadataText,
    resetError,
  };
};

export const ProvideFlashErrorText = ({ children }: IProps) => {
  const flashErrorTextValue = useProvideFlashErrorText();

  return (
    <FlashErrorTextContext.Provider value={flashErrorTextValue}>
      {children}
    </FlashErrorTextContext.Provider>
  );
};

export const useFlashErrorText = () => {
  const context = useContext(FlashErrorTextContext);

  if (context === undefined) {
    throw new Error('useFlashErrorText must be used within the ProvideFlashErrorText provider');
  }

  return context;
};
