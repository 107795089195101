/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import api from '../../services/api';
import errorHandler from '../../services/sentry';

import Page from '../Page';
import {
  BrandSelector as BrandSelectorComponent,
  Button,
  CopyText,
  CustomBulletListItem,
  Form,
  Footer,
  Header,
  InlineLinks,
  List,
  Paragraph,
  PrivacyPolicyModal,
  Select,
  Box,
} from '../../components';

import { useFlashErrorText } from '../../contexts/FlashErrorText';
import { useAppLoading } from '../../contexts/AppLoading';

import { IPageProps } from '../../interfaces/pages/page.interface';

const componentMap = {
  brandSelector: BrandSelectorComponent,
  button: Button,
  copyText: CopyText,
  customBulletListItem: CustomBulletListItem,
  form: Form,
  footer: Footer,
  header: Header,
  inlineLinks: InlineLinks,
  list: List,
  paragraph: Paragraph,
  privacyPolicyModal: PrivacyPolicyModal,
  select: Select,
  box: Box,
};
const BrandSelector = (props: IPageProps) => {
  const {
    componentTree, pageMetadata, setComponentTree, isPreview,
  } = props;

  const navigate = useNavigate();
  const { search } = useLocation();
  const { resetError } = useFlashErrorText();
  const { setAppLoading } = useAppLoading();

  const fetchData = useCallback(async () => {
    // first, clear any flash errors
    resetError();
    // set loading indicator
    setAppLoading(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = await api.getComponents(
      isPreview ? `/preview/brand-selector${search}` : '/oauth/brand-selector',
    );

    // reset loading indicator
    setAppLoading(false);

    if (data.routes) {
      navigate(data.routes.frontend);
    } else {
      setComponentTree(data);
    }
  }, []);

  useEffect(() => {
    fetchData()
      .catch((e: AxiosError) => {
        // reset loading indicator
        setAppLoading(false);
        // display error info if available
        if (e.response?.data) {
          setComponentTree(e.response.data);
        } else {
          errorHandler(e);
        }
      });
  }, [fetchData]);

  return (
    <Page
      componentMap={componentMap}
      componentTree={componentTree}
      pageMetadata={pageMetadata}
      setComponentTree={setComponentTree}
    />
  );
};

export default BrandSelector;
