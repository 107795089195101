import clsx from 'clsx';
import Link from '../Link';
import Button from '../Button';

import { IProps } from '../../interfaces/components/inlineLinks.interface';

const InlineLinks = ({
  text, linkClassName, linkAsButton, buttonClickHandler, disabled,
}: IProps) => {
  const splitOnLinks = text.split('|+|');
  // If there is no link, return the text
  if (splitOnLinks.length === 1) {
    return <span>{text}</span>;
  }

  return (
    <>
      {splitOnLinks.map((section, i) => {
        if (!section) return null;
        if (section.includes('@@')) {
          const [linkText, linkUrl] = section.split('@@');
          if (linkAsButton) {
            return (
              <Button
                key={linkText}
                text={linkText}
                handleClick={buttonClickHandler && buttonClickHandler}
                elementAttributes={{ id: `inline-link-${i}`, type: 'button' }}
                additionalClassNames={clsx('button-text-link', linkClassName)}
                disabled={disabled}
              />
            );
          } if (linkUrl && linkText) {
            return (
              <Link
                key={linkText}
                id={`inline-link-${i}`}
                path={linkUrl}
                text={linkText}
                className={disabled ? 'disabled' : ''}
              />
            );
          }
          // if only linkUrl available
          if (linkUrl) {
            return (
              <Link
                key={linkUrl}
                id={`inline-link-${i}`}
                path={linkUrl}
                text={linkUrl}
                className={disabled ? 'disabled' : ''}
              />
            );
          }
          // if only lnkText available
          if (linkText) {
            return <span key={linkText}>{linkText}</span>;
          }
          return null;
        }
        // non-link sections of the text
        return <span key={section}>{section}</span>;
      })}
    </>
  );
};

export default InlineLinks;
