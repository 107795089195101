/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { IProps } from '../../interfaces/components/fileUpload.interface';
import uploadIcon from '../../assets/images/icons/upload.svg';
import fileIcon from '../../assets/images/icons/file.svg';
import exitIcon from '../../assets/images/icons/exit.svg';
import check from '../../assets/images/icons/check.svg';
import api from '../../services/api';
import InlineLinks from '../InlineLinks';
import { FormContext } from '../Form/Form';
import { IFormContext } from '../../interfaces/components/form.interface';

const toggleContinueButton = (display) => {
  const continueButton = document.getElementById('continue-button');
  if (continueButton && display) {
    continueButton.classList.remove('hidden');
  } else if (continueButton && !display && !continueButton.classList.contains('hidden')) {
    continueButton.classList.add('hidden');
  }
};

const FileUpload = (props: IProps) => {
  const {
    id,
    name,
    inputClassName,
    maxFileSizeInBytes,
    text,
  } = props;
  const {
    chooseFile,
    instructions,
    verifyingVins,
    fileSizeExceeded,
    fileTypeInvalid,
  } = text;
  const [fileInfo, setFileInfo] = useState({ name: '', size: '', valid: false });
  const [status, setStatus] = useState<string | undefined>('');
  const [uploadError, setUploadError] = useState<boolean | undefined>(false);
  const [statusDescription, setStatusDescription] = useState<string | undefined>('');
  const postRoute = '/oauth/vin-import';
  const context = useContext<IFormContext | undefined>(FormContext);
  if (!context) {
    return null;
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const vinFile = e.target.files[0];
      setFileInfo((prevState) => ({ ...prevState, name: vinFile.name, size: `${(vinFile.size / 1024).toFixed(1)} kb` }));
      setUploadError(false);
      setStatusDescription('');

      if (!(vinFile.type === 'text/csv' || vinFile.type === 'text/plain')) {
        toggleContinueButton(false);
        setUploadError(true);
        setStatus(fileTypeInvalid);
      } else if (vinFile.size > maxFileSizeInBytes) {
        toggleContinueButton(false);
        setUploadError(true);
        setStatus(fileSizeExceeded);
      } else if (vinFile) {
      // read the file as text and send it to the /oauth/vin-import post route
        const reader = new FileReader();
        reader.onloadstart = () => {
          setStatus(verifyingVins);
        };
        // when the file has been loaded, we send the file to the backend
        reader.onloadend = async () => {
          const { data } = await api.postBackend(postRoute, {
            file: reader.result,
          });
          if (data?.error) {
            toggleContinueButton(false);
            setUploadError(true);
            setStatus(data.error);
            setStatusDescription(data.description);
            return;
          }
          toggleContinueButton(true);
          setFileInfo((prevState) => ({ ...prevState, valid: true }));
          setStatus(data);
          e.target.value = '';
        };
        reader.readAsText(vinFile);
      }
    }
  };

  const clearFile = async () => {
    toggleContinueButton(false);
    const { data } = await api.postBackend(postRoute, {
      clear: true,
    });
    if (data?.error) {
      setUploadError(true);
      setStatus(data.error);
      setStatusDescription(data.description);
    } else {
      setFileInfo({ name: '', size: '', valid: false });
      setUploadError(false);
      setStatusDescription('');
    }
    return true;
  };

  // sets the form data to select VINs manuallly
  const handleInlineLink = () => {
    context.setFormState({
      selectVinsManually: true,
    });
  };

  useEffect(() => {
    if (context.formState.selectVinsManually && context.submitFormData) {
      context.submitFormData()
        .catch((e: string) => {
          setUploadError(true);
          setStatusDescription(e);
        });
    }
  }, [context.formState]);

  return (
    <div className="file-upload-container">
      <InlineLinks
        text={instructions}
        linkClassName="instructions"
        linkAsButton
        buttonClickHandler={handleInlineLink}
      />
      <div className="input-file-container">
        <input
          className={clsx('input-file-field', inputClassName)}
          id={id}
          name={name}
          onChange={handleFileUpload}
          type="file"
          accept=".txt, .csv"
          data-testid="upload-input"
        />
        <img
          src={uploadIcon}
          alt="upload"
          width="24"
        />
        <h1 className="input-file-title">
          {chooseFile.split('|+|').map((section) => {
            if (section.includes('@@')) {
              const [linkText, linkUrl] = section.split('@@');
              if (linkUrl && linkText) {
                return (
                  <span key={section} className="choose-file-link">{linkText}</span>
                );
              }
            }
            // non-link sections of the text
            return <span key={section}>{section}</span>;
          })}

        </h1>
        <div className="input-file-subtext">CSV or TXT, 200KB max</div>
      </div>
      {/* upload progress */}
      {fileInfo.name
        && (
          <>
            <div className="upload-progress-container" data-testid="status-description">
              <img
                src={fileIcon}
                alt="file"
                width="36"
              />
              <div>
                <h3 className="file-name">
                  {fileInfo.name}
                </h3>
                <div className="upload-progress">
                  <span>{fileInfo.size}</span>
                  ·
                  <span className={uploadError ? 'upload-error' : 'upload-complete'}>
                    {status}
                    {!uploadError && fileInfo.valid && (
                    <img
                      src={check}
                      alt="check"
                      width="18"
                    />
                    )}
                  </span>
                </div>
              </div>
              <button
                id="clear"
                type="button"
                className="button clear-file"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={clearFile}
              >
                <img
                  src={exitIcon}
                  alt="Exit"
                  width="18"
                />
              </button>
            </div>
            {
              statusDescription
              && (
              <div className="status-description">
                <InlineLinks
                  text={statusDescription}
                  linkClassName="status-description"
                  linkAsButton
                  buttonClickHandler={handleInlineLink}
                />
              </div>
              )
            }
          </>
        )}
    </div>
  );
};

export default FileUpload;
