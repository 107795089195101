import { useState } from 'react';

import clipboard from '../../assets/images/icons/clipboard.svg';
import check from '../../assets/images/icons/check.svg';

import errorHandler from '../../services/sentry';

import { IProps } from '../../interfaces/components/copyText.interface';

const CopyText = ({ text }: IProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copy = async () => {
    if (navigator && navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
  };

  const handleCopy = () => {
    copy()
      .then(() => {
        setIsCopied(true);
        // flip the icon back to the clipboard after 3 seconds
        setTimeout(() => setIsCopied(false), 3000);
      })
      .catch((e: Error | string) => errorHandler(e));
  };

  return (
    <div className="copy-text-container">
      <span className="copy-text">{text}</span>
      <button aria-label="copy-button" type="button" className="copy-text-button" onClick={handleCopy} id="copy-button">
        <img
          src={isCopied ? check : clipboard}
          alt={isCopied ? 'checkmark' : 'clipboard icon'}
        />
      </button>
    </div>
  );
};

export default CopyText;
