import { createContext, useMemo, useState } from 'react';
import { ISelectionsContext, IProps } from '../interfaces/contexts/selections.interface';

export const SelectionsContext = createContext<ISelectionsContext | undefined>(undefined);

export const SelectionsContextProvider = ({ children }: IProps) => {
  const [language, setLanguage] = useState({ value: 'en-US' });
  const [country, setCountry] = useState({ value: 'US' });

  const contextValue = useMemo(() => ({
    language,
    setLanguage,
    country,
    setCountry,
  }), [language.value, country.value]);

  return (
    <SelectionsContext.Provider value={contextValue}>
      {children}
    </SelectionsContext.Provider>
  );
};
