/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useContext, useState } from 'react';
import clsx from 'clsx';

import { FormContext } from '../Form/Form';
import { formatIconPath } from '../../contexts/Theme';

import { IFormContext } from '../../interfaces/components/form.interface';
import { IProps } from '../../interfaces/components/textInput.interface';

const visibility = {
  hide: {
    id: 'hide',
    inputType: 'password',
    icon: '../../assets/images/icons/visibility.svg',
    altText: 'Show Password',
  },
  show: {
    id: 'show',
    inputType: 'text',
    icon: '../../assets/images/icons/visibility_off.svg',
    altText: 'Hide Password',
  },
};

const ruleSet = {
  emailOrTel: {
    pattern: '.+@.+|^[^A-Za-z@]*$',
    tip: 'Emails must include "@". Phone numbers should only have digits.',
  },
};

const TextInput = (props: IProps) => {
  const {
    icon,
    id,
    handleChange,
    name,
    placeholder,
    inputClassName,
    required,
    inputType,
    value,
    wrapperClassName,
    validationRule,
    additionalInputProps,
    handleKeyDown,
    disabled,
  } = props;
  const context = useContext<IFormContext | undefined>(FormContext);
  const isPassword = name === 'password';
  const [passwordVisibility, setPasswordVisibility] = useState(visibility.show);
  const [useInputType, setInputType] = useState(inputType);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (context) {
      context.setFormState({
        ...context.formState,
        [name]: {
          ...context.formState[name],
          value: e.target.value,
        },
      });
    } else if (handleChange) {
      handleChange(e.target.value);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility(passwordVisibility.inputType === 'password' ? visibility.show : visibility.hide);
    setInputType(passwordVisibility.inputType);
  };

  return (
    <div
      className={clsx('input-text-container', wrapperClassName)}
    >
      {icon && <img src={formatIconPath(icon)} alt={name} className="input-text-image" />}
      <label className="visually-hidden" htmlFor={id}>
        {placeholder}
      </label>
      <input
        className={clsx('input-text-field', inputClassName)}
        id={id}
        name={name}
        onChange={inputChange}
        placeholder={placeholder}
        required={required}
        spellCheck={false}
        type={useInputType}
        value={context?.formState[name].value || value || ''}
        pattern={validationRule && ruleSet[validationRule].pattern}
        title={validationRule && ruleSet[validationRule].tip}
        {...additionalInputProps}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {isPassword && (
        <button
          id={passwordVisibility.id}
          type="button"
          className="button-input-icon m-l-sm m-r-sm"
          onClick={togglePasswordVisibility}
        >
          <img
            src={formatIconPath(passwordVisibility.icon)}
            alt={passwordVisibility.altText}
          />

        </button>
      )}
    </div>
  );
};

export default TextInput;
