/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import api from '../../services/api';
import { useFlashErrorText } from '../../contexts/FlashErrorText';

import {
  IAccount,
  IAccountSignedOut,
  IAccountAction,
  IProps,
} from '../../interfaces/components/accountSelector.interface';

import { useAppLoading } from '../../contexts/AppLoading';
import { AccountSignedIn, AccountSignedOut } from './components';
import Footer from '../Footer';

const AccountSelector = ({
  accounts, actionButtons, setComponentTree, postRoute, footerContent,
}: IProps) => {
  const navigate = useNavigate();
  const { setFlashErrorText } = useFlashErrorText();
  const { appLoading, setAppLoading } = useAppLoading();

  const [accountDrawerOpen, setAccountDrawerOpen] = useState('');

  const handleReturnUserAction = async ({ action, deviceId, sessionId }: IAccountAction) => {
    const payload = {
      returnUser: {
        action,
        deviceId,
        sessionId,
      },
    };

    try {
      setAppLoading(true);
      const { data } = await api.postBackend(postRoute, payload);
      setAppLoading(false);

      // need to use window.location.href here bc we are redirecting outside of Connect
      if (data.redirect) {
        window.location.href = data.redirect;
      } else if (data.routes) {
        navigate(data.routes.frontend);
      } else {
        setComponentTree(data);
      }
    } catch (err: any) {
      setAppLoading(false);

      if (err.response && err.response.data) {
        if (err.response.data.flash) {
          setFlashErrorText(err.response.data.message);
          console.log(`Session ID: ${err.response.data.sessionId}`);
        } else {
        // display page error
          setComponentTree(err.response.data);
        }
      }
    }
  };

  const renderAccount = (account: IAccount | IAccountSignedOut) => {
    const commonProps = {
      sessionId: account.sessionId,
      deviceId: account.deviceId,
      username: account.username,
      brand: account.brand,
      logo: account.logo,
      handleReturnUserAction,

    };
    return 'signedOutText' in account ? (
      <AccountSignedOut
        {...commonProps}
        signedOutText={account.signedOutText}
        actionButtons={account.actionButtons}
        drawerOpen={accountDrawerOpen === account.sessionId}
        openDrawer={setAccountDrawerOpen}
      />
    ) : (
      <AccountSignedIn {...commonProps} />
    );
  };

  return (
    <div className="account-selector">
      <div className="accounts-container">
        {appLoading
        && (
        <div className="loading-container">
          <span className="spinner" />
        </div>
        )}
        <ul>
          {accounts.map((account) => (
            <li key={account.sessionId}>
              {renderAccount(account)}
            </li>
          ))}
        </ul>
      </div>
      <div className="m-t-auto offset-page-p-b">
        {actionButtons.map(({
          action, additionalClassNames, elementAttributes, text,
        }) => {
          const { deviceId, sessionId } = accounts[0];
          const { id, value } = elementAttributes;
          return (
            <button
              key={id}
              id={id}
              type="button"
              className={additionalClassNames}
              value={value}
              onClick={() => handleReturnUserAction({ action, deviceId, sessionId })}
              disabled={appLoading}
            >
              {text}
            </button>

          );
        })}
        <Footer {...footerContent} />
      </div>
    </div>
  );
};

export default AccountSelector;
