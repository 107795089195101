/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useContext } from 'react';
import clsx from 'clsx';

import { FormContext } from '../Form/Form';

import { IFormContext } from '../../interfaces/components/form.interface';
import { IProps } from '../../interfaces/components/toggleInput.interface';

const ToggleInput = ({
  disabled,
  id,
  label,
  name,
  status,
  inputType,
  value,
  icon,
  wrapperClassName,
  isVin,
  // these are used if ToggleInput is being used as a child component in connect-frontend
  // and state is handled by the parent component
  checkedStatus,
  handleChange,
}: IProps) => {
  const context = useContext<IFormContext | undefined>(FormContext);

  const handleInputChange = () => {
    if (handleChange) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      handleChange(value);
    } else if (context) {
      if (inputType === 'radio') {
        const optionalUpdates = {} as { selectedIcon?: string, selectedPlaceholder?: string };
        if (icon) {
          optionalUpdates.selectedIcon = icon;
        }
        if (typeof label.text === 'string') {
          optionalUpdates.selectedPlaceholder = label.text;
        }
        context.setFormState({
          ...context.formState,
          selectedRadio: value,
          ...optionalUpdates,
        });
      } else if (inputType === 'checkbox') {
        context.setFormState({
          ...context.formState,
          [name]: {
            ...context.formState[name],
            [value]: !context.formState[name][value],
          },
        });
      }
    }
  };

  let checked = false;
  // if state is handled by parent components
  if (checkedStatus && handleChange) {
    checked = checkedStatus;
  // otherwise it's controlled by form context
  } else if (inputType === 'radio') {
    checked = context?.formState.selectedRadio === value;
  } else if (inputType === 'checkbox') {
    checked = context?.formState[name][value];
  }

  return (
    <label
      className={clsx('input-toggle-label', disabled && 'disabled', wrapperClassName)}
      htmlFor={id}
      id={`${id}-label`}
    >
      <input
        className="input-toggle-hidden m-none"
        checked={checked}
        name={name}
        id={id}
        value={value}
        disabled={disabled}
        type={inputType}
        onChange={handleInputChange}
      />
      <span className={clsx('input-toggle-custom', inputType, status)} />
      <span
        className={clsx(isVin ? 'input-toggle-label-text-vin' : 'input-toggle-label-text', label.className)}
      >
        {label.text}
      </span>
    </label>
  );
};

export default ToggleInput;
