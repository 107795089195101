import * as Sentry from '@sentry/react';

/* istanbul ignore next */
export default function (error: Error | string) {
  if (error instanceof Error) {
    Sentry.captureException(error);
  } else {
    Sentry.captureMessage(error);
  }
}
