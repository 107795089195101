/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import ToggleInput from '../ToggleInput';
import Tooltip from '../Tooltip';

import { IProps } from '../../interfaces/components/vehicleToggle.interface';

const VehicleToggle = ({
  checkedStatus,
  disabled,
  handleChange,
  id,
  inputType,
  name,
  status,
  vehicle,
  wrapperClassName,
}: IProps) => {
  let label;
  if (vehicle.displayName) {
    label = {
      text: (
        <Tooltip tooltipContent={vehicle.displayVin}>
          <span className="border-bottom-dashed">{vehicle.displayName}</span>
        </Tooltip>
      ),
    };
  } else {
    label = {
      text: vehicle.displayVin,
    };
  }

  return (
    <ToggleInput
      checkedStatus={checkedStatus}
      disabled={disabled}
      handleChange={handleChange}
      id={id}
      label={label}
      name={name}
      status={status || ''}
      inputType={inputType}
      value={vehicle.id}
      wrapperClassName={wrapperClassName || ''}
      isVin={!vehicle.displayName}
    />
  );
};

export default VehicleToggle;
